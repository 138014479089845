<template>
  <div>
    <div class="grid mt-3 mb-3">
      <img :src="images.comic1" />
      <img :src="images.comic2" />
      <img :src="images.comic3" />
      <img :src="images.comic4" />
    </div>
  </div>
</template>

<script>
import { storage } from "../../../firebase.config";
import { getDownloadURL, ref } from "firebase/storage";
export default {
  data() {
    return {
      images: {
        comic1: "",
        comic2: "",
        comic3: "",
        comic4: "",
      },
    };
  },
  created() {
    Object.keys(this.images).forEach((key) =>
      getDownloadURL(ref(storage, `fb/${key}.png`)).then((url) => {
        this.images[key] = url;
      })
    );
  },
};
</script>

<style scoped>
.grid {
  display: grid;
  grid-template-columns: auto auto;
  margin: auto;
  width: 52%;
}
img {
  width: 100%;
  padding: 2%;
}
@media screen and (max-width: 800px) {
  .grid {
    width: 80%;
  }
}
</style>
