<template>
  <div class="mb-5">
    <h2><span>Facebook Content</span></h2>
    <img :src="images.wasu" alt="wasu the mascot" class="wasu" />
    <iframe
      src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Flpc.lannaproducts%2F&tabs=timeline&width=500&height=500&small_header=false&adapt_container_width=false&hide_cover=false&show_facepile=false&appId"
      width="500"
      height="500"
      style="border: none; overflow: hidden"
      scrolling="no"
      frameborder="0"
      allowfullscreen="true"
      allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
    ></iframe>
    <a href="https://www.facebook.com/lpc.lannaproducts/"
      ><img :src="images.fb" class="box" title="Click Me"
    /></a>
    <Comic />
    <img :src="images.scan" class="scan" />
    <h3 v-html="$t('fb')"></h3>
    <h3>
      Facebook Fanpage :
      <a href="https://www.facebook.com/lpc.lannaproducts/">Lanna Products</a>
    </h3>
    <!--    <vue-facebook-page-->
    <!--      data-href="https://www.facebook.com/lpc.lannaproducts/"-->
    <!--      link-text="Your Page Name"-->
    <!--    />-->
  </div>
</template>

<script>
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import Comic from "@/components/fb/Comic.vue";
export default {
  components: { Comic },
  data() {
    return {
      images: {
        wasu: "",
        fb: "",
        scan: "",
      },
    };
  },
  created() {
    const firebaseApp = firebaseApp;
    Object.keys(this.images).forEach((key) =>
      getDownloadURL(ref(getStorage(firebaseApp), `fb/${key}.png`)).then(
        (url) => {
          this.images[key] = url;
        }
      )
    );
  },
};
</script>

<style scoped>
h2 {
  width: 90%;
  text-align: center;
  border-bottom: 5px solid #2c3e50;
  line-height: 1px;
  margin: auto;
  padding-top: 5%;
  margin-bottom: 3%;
}
h2 span {
  background: #fff;
  padding: 0 20px;
}
.wasu {
  width: 100%;
  padding: 5%;
  padding-top: 0;
}
.box {
  width: 100%;
  padding: 0 0 5% 0;
}
.scan {
  width: 80%;
  padding: 0 0 3% 0;
}
a {
  color: #2c3e50;
}
@media screen and (max-width: 630px) {
  h3 {
    font-size: 18px;
  }
  .box {
    width: 100%;
    padding: 0;
  }
}
</style>
